<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/company' }">公司管理</el-breadcrumb-item>
          <el-breadcrumb-item>新增公司</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="公司名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入公司名称"></el-input>
          </el-form-item>
          <el-form-item label="公司地址" prop="addr">
            <el-input v-model="form.addr" placeholder="请输入公司地址"></el-input>
          </el-form-item>
          <el-form-item label="菜单配置">
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item title="公司菜单配置" name="1">
                <el-tree
                  ref="menuRefs"
                  :data="dataTree"
                  show-checkbox
                  node-key="id"
                  default-expand-all
                  @node-click="handleNodeClick"
                  :props="defaultProps">
                </el-tree>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
          
          <el-form-item label="">
            <el-button type="primary" @click="onSubmit" size="small">确定</el-button>
            <el-button size="small" @click="$router.push('/company')">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        addr:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      loading: false,
      activeNames: ['1'],
      dataTree: [],
      defaultProps: {
        children: 'childTree',
        label: 'title'
      }
    }
  },
  methods: {
    handleChange(val) {},
    handleNodeClick(e) {},
    loadTree() {
      this.$ajax.get('menuStructure').then(res=> {
        this.dataTree = res.data
      })
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let getCheckedKeys = this.$refs.menuRefs.getCheckedKeys()
          let getHalfCheckedKeys = this.$refs.menuRefs.getHalfCheckedKeys()
          getCheckedKeys = getCheckedKeys.concat(getHalfCheckedKeys)

          if(getCheckedKeys == '') {
            this.$message.error('请选择权限配置')
            return
          }
          let form = {
            addr: this.form.addr,
            name: this.form.name,
            menuIds: getCheckedKeys
          }

          this.loading = true
          this.$ajax.post('companyCreate', form).then(res => {
            if (res.code == 0) {
              this.form = {}
              this.$message.success('成功')
              this.$router.push('/company')
            }
            this.loading = false
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    }
  },
  mounted() {
    this.loadTree()
  }
}
</script>
<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.el-input { width:50%; }
.el-collapse {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  overflow: hidden;
  width: 50%;
}
.el-collapse-item {
  box-shadow: 0px 0px 0px rgba(211, 211, 211, 0.5);
  border: 0px solid #eee;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
}
/deep/.el-collapse-item__header {
  height: 42px;
  padding: 0 10px;
  border-bottom: 0;
}
/deep/.el-tree-node__label {
  font-size: 15px;
  line-height: 10px;
  letter-spacing: 2px;
}
/deep/.el-tree-node__content {
  height: 35px;
}
</style>
<style>

</style>